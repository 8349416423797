<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <!-- <el-input v-if="search.search_by == 'vendor_name'" clearable style="width: 150px" size="small" type="text" v-model="search.search_keyword" placeholder="Input search keyword" class="mr-2"/> -->
            <!-- <el-select size="small" v-model="search.search_by" class="mr-2" style="width: 150px" @change="ganti">
              <el-option v-for="(item, index) in search_options" :key="index" :value="item.value" :label="item.text"/>
            </el-select> -->
            <el-select
              hidden
              class="mr-2"
              size="small"
              v-model="search.vendor_id"
              placeholder="Search by Vendor">
              <el-option value="" label="All">All</el-option>
              <el-option v-for="(item, index) in vendors.list" :value="item.vendor_id" :key="index" :label="item.vendor_name"/>
            </el-select>
            <el-button type="primary" @click="handlerShowModal('Search')" size="small"><font-awesome-icon icon="search"/> Search</el-button>
            <el-button type="primary" @click="handlerShowModal('Download')" size="small"><font-awesome-icon icon="download"/> Download</el-button>
            <el-button type="info" @click="handlerShowModal('Regenerate')" size="small"><font-awesome-icon icon="sync"/> Regenerate Report</el-button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th>Report Date</th>
                <th width="50%">Name</th>
                <th>Begining Balance</th>
                <th>End Balance</th>
                <th>Credit</th>
                <th>Topup Amount</th>
                <th>Adjustment Amount</th>
                <th>Input Date</th>
                <th class="child1-sticky">Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.report_date_str }}</td>
                <td>{{ item.vendor.vendor_name }}</td>
                <td>{{ item.beginning_balance_str }}</td>
                <td>{{ item.end_balance_str }}</td>
                <td>{{ item.credit_str }}</td>
                <td>{{ item.total_topup_str }}</td>
                <td>{{ item.nominal_adjustment_str }}</td>
                <td>{{ item.created_at_str }}</td>
                <td class="text-nowrap text-muted child1-sticky">
                  <button @click="viewDetail(item)" title="View Detail" type="button" class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye"/></button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" ></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="search.per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="search.page"
            />
          </div>
        </div>
        <b-modal v-model="show_modal.regenerate_report" :title="titleModal" hide-footer>
          <b-form @submit.prevent="handlerRegenerateReport" @reset="show_modal.regenerate_report = false; resetForm()">
            <b-form-group label="Vendor">
              <el-select
                class="w-full"
                size="small"
                v-model="v$.form.vendor_id.$model"
                placeholder="Choose Vendor">
                <el-option v-for="(item, index) in vendors.list" :value="item.vendor_id" :key="index" :label="item.vendor_name"/>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.vendor_id.$invalid" v-if="v$.form.vendor_id.$errors[0]">
                <span> {{ v$.form.vendor_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Date">
              <el-date-picker
                v-model="v$.form.date.$model"
                type="daterange"
                size="small"
                @input="handlerFormDateChange"
                :picker-options="datePickerOptions"
                class="w-full"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker>
              <b-form-invalid-feedback :state="!v$.form.start_at.$invalid" v-if="v$.form.start_at.$errors[0]">
                <span> {{ v$.form.start_at.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex flex-row float-right">
              <el-button @click="handlerRegenerateReport" :loading="loading.regenerate_report" class="mr-2 ml-2" size="small" type="primary">{{titleModalButton}}</el-button>
              <el-button @click="show_modal.regenerate_report = false; resetForm()" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal size="xl" v-model="showDetail" :title="'Detail #' + selected_data.id" hide-footer>
          <el-skeleton :loading="loading_detail" :rows="6" animated />
          <el-descriptions v-if="!loading_detail" class="margin-top mb-3" title="Information" :column="2" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                Vendor Name
              </template>
              {{ selected_data.vendor_name || '-' }}
            </el-descriptions-item>
            <el-descriptions-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                Report Date
              </template>
              {{ selected_data.report_date_str }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-document"></i>
                Created Date
              </template>
              {{ selected_data.created_at_str }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-message"></i>
                Begining Balance
              </template>
              {{ selected_data.beginning_balance_str }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-wallet"></i>
                End Balance
              </template>
              {{ selected_data.end_balance_str }}
            </el-descriptions-item>
          </el-descriptions>
          <div class="table-responsive">
            Top Up
            <table v-if="!loading_detail" class="table table-vcenter w-full">
              <thead>
                <tr>
                  <th>created_at</th>
                  <th>topup_at</th>
                  <th>nominal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in selected_data.detail.topup_detail" :key="i">
                  <td>{{ item.created_at_str }}</td>
                  <td>{{ item.topup_at_str }}</td>
                  <td>{{ item.nominal_str }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="background-color: #f4f6fa; font-weight: bold;">
                  <td colspan="2">Subtotal</td>
                  <td>{{ selected_data.detail.total_topup_str }}</td>
                </tr>
            </tfoot>
            </table>
          </div>

          <div class="table-responsive">
            Adjustment
            <table v-if="!loading_detail" class="table table-vcenter w-full">
              <thead>
                <tr>
                  <th>nominal_type</th>
                  <th>description</th>
                  <th>created_at</th>
                  <th>report_date</th>
                  <th>nominal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in selected_data.detail.adjustment_detail" :key="i">
                  <td>{{ item.nominal_type }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.created_at_str }}</td>
                  <td>{{ item.report_date_str }}</td>
                  <td>{{ item.nominal_str }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="background-color: #f4f6fa; font-weight: bold;">
                  <td colspan="4">Subtotal</td>
                  <td>{{ selected_data.detail.total_adjustment_str }}</td>
                </tr>
            </tfoot>
            </table>
          </div>

          <div class="table-responsive">
            Credit
            <table v-if="!loading_detail" class="table table-vcenter w-full">
              <thead>
                <tr>
                  <th>source_type</th>
                  <th>created_at</th>
                  <th>payment_method</th>
                  <th>coin used</th>
                  <th>base_price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in selected_data.detail.order_detail" :key="i">
                  <td>{{ item.source_type }}</td>
                  <td>{{ item.created_at_str }}</td>
                  <td>{{ item.order.pm.payment_name }}</td>
                  <td>{{ item.order.coin_used }}</td>
                  <td>{{ item.base_price_str }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="background-color: #f4f6fa; font-weight: bold;">
                  <td colspan="4">Subtotal</td>
                  <td>{{ selected_data.detail.total_credit_order_detail_str }}</td>
                </tr>
            </tfoot>
            </table>
          </div>
      </b-modal>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  GET_DEPOSITS,
  GET_VENDORS,
  POST_REPORTS,
  GET_DETAIL_REPORTS,
  DOWNLOAD_DEPOSITS,
} from '@/store/modules/topups';

export default {
  name: 'Deposits',
  metaInfo: {
    title: 'Deposits',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        vendor_id: { required },
        start_at: { required },
        end_at: { required },
        date: { required },
      },
    };
  },
  data() {
    return {
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: false,
      loader: null,
      form: {
        vendor_id: '',
        date: [],
        start_at: '',
        end_at: '',
        date_filter: [],
      },
      search: {
        search_keyword: '',
        search_by: '',
        vendor_id: '',
        page: 1,
        per_page: 20,
        date_filter: [],
      },
      search_options: [
        { value: 'vendor_name', text: 'Name' },
      ],
      vendors: {
        list: [],
        count: 0,
      },
      show_modal: {
        regenerate_report: false,
      },
      loading: {
        regenerate_report: false,
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      selected_data: {
        detail: {},
        vendor_name: '',
      },
      showDetail: false,
      loading_detail: false,
      titleModal: '',
      titleModalButton: '',
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.search.page - 1) * this.search.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.search.per_page) {
          return this.total_rows;
        }
        return this.search.page * this.search.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    // const loader = this.$loading.show();
    await this.getList();
    this.getVendorList();
    // loader.hide();
  },
  methods: {
    onChange(event) {
      console.log(event.target.value);
      this.search.search_by = event.target.value;
    },
    ganti(event) {
      console.log('test', event);
      this.search.search_keyword = '';
      this.search.search_by = event;
    },
    hideshowdiv(value) {
      console.log('val', value);
      // this.search.search_by = value;
    },
    pageChangeHandler(page) {
      this.search.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search_by = this.search.search_by;
      // this.search_by = 'academic_name';
      this.search_keyword = this.search.search_keyword;
      this.getList();
    },
    refreshHandler() {},
    handlerRegenerateReport() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.search.date_filter[0] = this.form.start_at;
      this.search.date_filter[1] = this.form.end_at;
      this.search.vendor_id = this.form.vendor_id;
      this.refreshList();
    },
    handlerFormDateChange([start, end]) {
      this.form.start_at = moment(start).format('YYYY-MM-DD');
      this.form.end_at = moment(end).format('YYYY-MM-DD');
    },
    async getVendorList() {
      await this.$store.dispatch(GET_VENDORS, {
      }).catch(() => {});
      const { rows, count } = this.$store.getters.vendors_topup;
      this.vendors.count = count || 0;
      this.vendors.list = rows;
    },
    async getList2() {
      this.isLoading = true;
      await this.$store.dispatch(GET_DEPOSITS, this.search).catch((err) => {
        console.log('err');
        console.log(err);
      });
      const { rows, count } = this.$store.getters.vendors_deposits;
      this.total_rows = count || 0;
      if (count) {
        this.list = rows;
      }
      // this.list = rows;
      this.isLoading = false;
    },
    async getList() {
      if (this.search.date_filter.length === 0) {
        this.search.date_filter[0] = moment().startOf('month').format('YYYY-MM-DD');
        this.search.date_filter[1] = moment().format('YYYY-MM-DD');
      }
      this.isLoading = true;
      if (this.search.action === 'download') {
        this.search.page = '';
        this.search.per_page = '';
      }
      await this.$store.dispatch(this.search.action === 'download' ? DOWNLOAD_DEPOSITS : GET_DEPOSITS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        ...this.search,
      }).then((result) => {
        if (this.search.action === 'download') {
          const anchor = document.createElement('a');
          anchor.href = `data:${result.headers['content-type']},${encodeURIComponent(result.data)}`;
          anchor.target = '_blank';
          anchor.download = `Deposits-Report-${this.search.date_filter[0]}-${this.search.date_filter[1]}.csv`;
          anchor.click();
          this.search.action = '';
          this.search.date_filter = [];
        } else {
          const { rows, count } = this.$store.getters.vendors_deposits;
          this.total_rows = count || 0;
          if (count) {
            this.list = rows;
          } else {
            this.list = [];
          }
          this.isLoading = false;
        }
      }).catch((err) => {
        console.log(err);
      });
      this.isLoading = false;
    },
    async refreshList() {
      this.loading.regenerate_report = true;
      if (this.titleModalButton === 'Regenerate') {
        await this.$store.dispatch(POST_REPORTS, this.form).then(() => {
        }).catch((err) => {
          console.log('catch');
          console.log(err);
          if (err.response?.data?.error) {
            const k = `response_error.${err.response?.data?.error.title}`;
            this.err_msg = this.$t(k);
            if (this.err_msg === k) {
              this.err_msg = err.response?.data?.error.message;
            }
          }
          this.err_show = true;
        });
        this.resetForm();
      }
      this.getList();
      this.show_modal.regenerate_report = false;
      this.loading.regenerate_report = false;
    },
    resetForm() {
      this.form = {
        vendor_id: '',
        date: [],
        start_at: '',
        end_at: '',
      };
    },
    async viewDetail(item) {
      this.selected_data = item;
      this.selected_data.vendor_name = item.vendor.vendor_name;
      this.showDetail = true;
      this.loading_detail = true;
      await this.$store.dispatch(GET_DETAIL_REPORTS, item.id).catch(() => {});
      this.selected_data.detail = this.$store.getters.detail_reports.rows[0];
      console.log(this.$store.getters.detail_reports.rows[0]);
      /*
      this.selected_data.details = this.$store.getters.detail_reports.rows[0].map((v) => {
        return v;
      });
      */
      this.loading_detail = false;
    },
    /* handlerDownloadBtn() {
      this.search.action = 'download';
      this.search.date_filter[0] = this.form.start_at;
      this.search.date_filter[1] = this.form.end_at;
      this.getList();
      this.search.date_filter[0] = '';
      this.search.date_filter[1] = '';
    }, */
    handlerShowModal(data) {
      console.log(data);
      if (data === 'Regenerate') {
        this.search.action = '';
        this.show_modal.regenerate_report = true;
        this.titleModal = 'Regenerate Report';
        this.titleModalButton = 'Regenerate';
      } else if (data === 'Search') {
        this.show_modal.regenerate_report = true;
        this.titleModal = 'Search';
        this.titleModalButton = 'Search';
      } else {
        this.search.action = 'download';
        this.show_modal.regenerate_report = true;
        this.titleModal = 'Download Report';
        this.titleModalButton = 'Download';
      }
    },
  },
};
</script>
